import React, { Component } from 'react'

import moment from 'moment'

//================ Components ===============================
import * as MM from '../../../Types'

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'

import DomHelper from '../../helpers/DomHelper'
import LengthCounterHelperText from '../../shared/TextLengthCounter'
import Validator from '../../../businesslayer/validation//notificationValidator'
//#region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteActionItemsReducer'

import { actions as actionDashboard } from '../../../reducers/actionDashboardReducer'

import selectors from '../../../selectors/minuteActionItemsSelectors'
import {
    ColorBaseWhite,
    ColorBaseBlueMedium,
    ColorBaseSkyBlue,
    ColorBaseGreyNew,
    ColorBaseDarkBlack,
    ColorBaseDarkRed
} from 'assets/styles/variables'
import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    NewDialogTitle,
    AddActionEditActionButton,
    NewDialogTextField
} from 'components/shared/StyledComponents'
import ChipInputField, { makeOnChipChange } from 'components/chip-input/chip-input-field'
import { css, ClassNames } from '@emotion/react'
import UserChip from 'components/shared/UserChip'
import { withStyles } from '@material-ui/core/styles'
import { errorMsgStyle, labelStyle } from '../components/InvitationView/InvitationView'
import { AtlasIcon } from 'web-components/atlas-icon'
import { StyledAtlasButtonOld } from '../components/ActionItemEditDialog/ActionItemEditDialog'
import {
    Assignees,
    AssigneeTextField,
    InputLabelStyled
} from '../components/form/AssigneeChipInputField'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

//#endregion

//#region Styles
const styles = () => ({
    iconButton: {
        height: '40px',
        width: '40px',
        '&:focus': {
            border: `2px solid ${ColorBaseSkyBlue}`,
            borderRadius: '4px'
        }
    }
})

export const getDialogTitleStyle = () => {
    return {
        backgroundColor: ColorBaseBlueMedium,
        color: ColorBaseWhite,
        height: 50,
        fontSize: 18,
        padding: 0,
        paddingLeft: 25,
        lineHeight: '50px',
        fontWeight: 'normal',
        marginBottom: 0
    }
}
export const inputText = css`
    .MuiInputBase-root {
        font-size: 16px;
        font-family: 'Source Sans Pro';
        font-weight: 400;
    }
    .MuiFormHelperText-root {
        color: ${ColorBaseDarkRed};
        font-family: 'Source Sans Pro';
        font-size: 13px;
        font-weight: 400;
    }
`
export const textEditStyle = css`
    .MuiInputBase-root {
        height: 40px;
    }
`

const dialogHeaderStyle = css`
    display: flex;
    align-items: center;
`

//#endregion

//#region Props

type Props = {
    actions: {
        initNotification: (...args: any[]) => void
        validateNotification: (...args: any[]) => void
        resetNotification: () => void
        sendNotification: (...args: any[]) => void
        editActionDashboardFullfilled: (...args: any[]) => void
    }

    action: any
    minutesItem: any
    onClose: () => void
    classes: any
    onSaved: (action: any) => void

    notification: MM.ActionNotification
    dateFormat: string
    savedAction: any
    isSendingNotification: boolean
    platform: string
    reloadActions: () => void
    handleShowToast?: () => void
    setIndicator: any
}

type ChipInputType = {
    focus: () => void
    state: any
    autoComplete?: any
} & JSX.Element

//#endregion

//#region Implementation

class NotificationView extends Component<Props> {
    addresseeInput?: ChipInputType
    subjText: string = ''
    bodyText: string = ''

    state = {
        visible: false
    }

    componentDidUpdate(prevProps: Props) {
        if (this.state.visible || prevProps.isSendingNotification) {
            return
        }

        //That is a visual trick to tackle problem of dialog changing its position after being loaded.
        //We let it load and reposition but show it after reporsition. That is around 200 msec for every thing.
        //We also make sure that overlay shown right away to make impression of fast launch.
        //I.e. overlay without dialog will stay for about 200msec and then dialog will transition in from 0 opacity.
        setTimeout(() => {
            if (!this.state.visible && this.addresseeInput) {
                this.addresseeInput.focus()
            }

            this.setState({ visible: true })
        }, 200)
    }

    componentDidMount() {
        this.props.actions.initNotification(this.props.action)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { action, savedAction, notification } = this.props
        if (!notification) {
            return
        }
        if (!notification.to) {
            notification.to = []
            if (!this.actionHasValidAssignees(action)) {
                return
            }
            this.initDefaultNotificationTo(action, notification)
        }

        const isNewNotification = action && nextProps?.notification && nextProps?.dateFormat

        if (isNewNotification && nextProps.notification.body === null) {
            nextProps.notification.body = i18n.t('EMAIL_BODY', {
                to: this.getExistingAddresseeData()?.map((c) => c?.text),
                committee: action?.committee,
                siteName: transientStorageManager?.siteName,
                status: action.status,
                actionText: action.text,
                dueDate: this.getDueDateFormatted(action, nextProps.dateFormat),
                assigneeList: this.getAssignees(action)
            })

            this.props.actions.validateNotification(nextProps.notification)
        }

        if (!savedAction && nextProps.savedAction) {
            //Save is done we can pass control to parent
            this.props.onSaved(nextProps.savedAction)
            this.props.onClose()
        }
    }

    private onCloseDialog = () => {
        this.props.onClose()
        this.props.actions.resetNotification()
    }

    private onNotify = async () => {
        this.props.setIndicator(true)
        let { notification, action, platform } = this.props
        notification.platform = platform

        const hasErrors =
            notification && notification.validationErrors && notification.validationErrors.size > 0

        if (!!hasErrors) {
            return
        }
        if (action && notification) {
            //We update object with action id and minutes id so
            //data layer would construct call correctly
            notification.minutesId = action.minutesId
            notification.actionId = action.id
            if (action.committee_id) notification.committee_id = action.committee_id
            if (notification && notification.minutesId && notification.actionId) {
                const data = (await this.props.actions.sendNotification(notification)) as any
                this.props.actions.editActionDashboardFullfilled(data?.value)
                this.props.onSaved(data?.value)
                this.props.reloadActions()
                this.onCloseDialog()
            }
        }
        this.props.setIndicator(false)
    }

    private onBodyChange = (val) => {
        let { notification } = this.props

        notification.body = val

        this.props.actions.validateNotification(notification)
    }

    private onSubjectChange = (value) => {
        let { notification } = this.props

        notification.subject = value

        this.props.actions.validateNotification(notification)
    }

    private getAssignees = (action) => {
        if (!action.assignees) {
            return ''
        }

        const result = action.assignees.reduce((acc, current) => {
            return `${acc}${acc ? ',' : ''} ${current.text ?? current.display_name}`
        }, '')
        return result || i18n.t('UNASSIGNED')
    }

    private getDueDateFormatted = (action: any, dateFormat: string): string => {
        if (!dateFormat) {
            dateFormat = 'MMMM DD, YYYY'
        }
        const dueDate = action?.dueDate ? action.dueDate : action.due_date
        const converedDate = moment(dueDate).isValid()
            ? moment(dueDate).format(dateFormat)
            : i18n.t('NO_DUE_DATE_EMAIL')

        return converedDate
    }

    private preprocessAddresseeOnAdd = (addressee) => {
        //BREAKING CHANGE Since 1.0: Control no longer sends the same object from autoselect so we have to
        //preprocess it to find out if that is our object
        if (!addressee.email && typeof addressee.value === 'object') {
            const { action } = this.props

            if (!this.actionHasValidAssignees(action)) {
                return addressee
            }

            let assigeesWithEmails = this.getActionAssigneesEmails(action)

            if (!assigeesWithEmails) {
                return addressee
            }

            const contact = assigeesWithEmails.find((c) => `${c.id}` === addressee.value.key)

            if (!contact) {
                return addressee
            }

            return {
                text: addressee.text,
                email: contact.email,
                id: addressee.value.key,
                value: addressee.value
            }
        }

        return addressee
    }

    private onAddresseeAdd = (addressee: any): void => {
        const { notification } = this.props

        if (!notification.to) {
            notification.to = Array<MM.ActionNotificationTo>()
        }

        let newAddressee = addressee
        newAddressee = this.preprocessAddresseeOnAdd(addressee)

        if (newAddressee.email && newAddressee.id && notification) {
            //Picking up from the list
            notification.to.push(newAddressee)
        } else {
            if (newAddressee.text && newAddressee.text.length > 5) {
                notification.to.push({
                    text: newAddressee.text,
                    email: newAddressee.text,
                    id: null,
                    isInverted: false,
                    value: newAddressee.text
                })
            }
        }

        //After we add new item, the chip input opens autocomplete right away
        //So to prevent it we close it explicitly
        const input = this.addresseeInput
        if (input && input.autoComplete) {
            input.autoComplete.close()
        }

        this.props.actions.validateNotification(notification)

        if (this.addresseeInput) {
            //Some hacky way to override default flow of the control
            this.addresseeInput.state.inputValue = ''
        }
    }

    private actionHasValidAssignees = (action: { assignees: Array<any> }): boolean => {
        if (!action || !action.assignees) {
            return false
        }

        if (!action.assignees.length) {
            return false
        }
        return true
    }

    private getActionAssigneesEmails = (action: { assignees: Array<any> }): Array<any> | null => {
        let assigeesWithEmails = action.assignees.filter((c) => c.email)

        if (!assigeesWithEmails.length) {
            return null
        }

        return assigeesWithEmails
    }

    private getNonAddedAssigneesEmails = (assigeesWithEmails: Array<any>, added: Array<any>) => {
        //Dont show ones we already added into the chip edit
        return assigeesWithEmails.filter(
            (c) =>
                !added.find((existing) => {
                    return existing.id === c.id || existing.email === c.email
                })
        )
    }
    private renderAddresseeDataSource = () => {
        const { action, notification } = this.props

        if (!notification) {
            return
        }

        if (!notification.to) {
            notification.to = Array<MM.ActionNotificationTo>()
        }

        if (!this.actionHasValidAssignees(action)) {
            return
        }

        let assigeesWithEmails = this.getActionAssigneesEmails(action)

        if (!assigeesWithEmails) {
            return
        }

        assigeesWithEmails = this.getNonAddedAssigneesEmails(assigeesWithEmails, notification.to!)

        return assigeesWithEmails.map((item) => {
            return {
                text: item.text,
                email: item.email,
                id: item.id,
                value: item.text
            }
        })
    }

    private isValidExistingAddressee = (item): boolean => {
        if (item.email && Validator.validateEmail(item.email)) {
            return true
        }

        if (item.text && Validator.validateEmail(item.text)) {
            item.email = item.text
            return true
        }
        if (item.display_name && Validator.validateEmail(item.display_name)) {
            item.email = item.display_name
            return true
        }

        return false
    }

    private initDefaultNotificationTo = (action, notification) => {
        setTimeout(() => this.props.actions.validateNotification(notification), 50)

        let existingTo = action.assignees.filter(this.isValidExistingAddressee)
        existingTo.forEach((c) => (c.isInverted = false))

        notification.to = [...existingTo]
    }

    private mapExistingAddresseeToData = (exstingAddressee: Array<any>): Array<any> => {
        return exstingAddressee.map((item) => {
            const text: string = item.isInverted ? item.email : item.text ?? item.display_name
            const email: string = item.email
            const id: string = item.id || item.email

            return {
                text: text,
                email: email,
                id: id,
                value: text
            }
        })
    }

    private getExistingAddresseeData = () => {
        //Here we prepare data for chips rendering
        const { action, notification } = this.props

        if (!notification) {
            return null
        }

        if (!notification.to) {
            notification.to = []

            if (!this.actionHasValidAssignees(action)) {
                return
            }

            this.initDefaultNotificationTo(action, notification)
        }

        return this.mapExistingAddresseeToData(notification.to)
    }

    private onDeleteChip = (value) => {
        const { notification } = this.props

        if (!notification.to) {
            return
        }

        const toRemove = notification.to.find((c) => (c.id || c.value) === value.id)

        if (toRemove) {
            const index = notification.to.indexOf(toRemove)
            notification.to.splice(index, 1)

            if (notification.invalidEmails) {
                notification.invalidEmails.delete(toRemove.email)
            }

            this.props.actions.validateNotification(notification)
        }
    }

    private onResize = () => {
        const { notification } = this.props
        if (!this.state.visible || !notification) {
            return
        }

        this.props.actions.validateNotification(notification)
    }

    private getValidationErrors = (): {
        toError?: string
        bodyError?: string
        subjError?: string
    } => {
        let toError: string | undefined
        let bodyError: string | undefined
        let subjError: string | undefined

        const { notification } = this.props

        if (notification && notification.validationErrors) {
            toError =
                notification.validationErrors.get('to') ||
                notification.validationErrors.get('email')

            bodyError = notification.validationErrors.get('body')
            subjError = notification.validationErrors.get('subject')
        }

        return {
            toError,
            bodyError,
            subjError
        }
    }

    private renderNotificationSubject = (subjError?: string) => {
        const { notification } = this.props

        let subjectText = ''

        if (notification && notification.subject) {
            subjectText = notification.subject
        }

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            margin-bottom: 0.9rem;
                            ${errorMsgStyle}
                            ${inputText}
                        `}>
                        <label
                            className={css`
                                ${labelStyle}
                            `}>
                            {translate('NOTIFICATION_SUBJECT')}*
                        </label>
                        <NewDialogTextField
                            id="subject_text"
                            required
                            aria-required="true"
                            helperText={
                                <LengthCounterHelperText
                                    errorText={subjError}
                                    maxValue={500}
                                    value={subjectText}
                                />
                            }
                            className={css`
                                input {
                                    padding: 10px 16px;
                                }
                                ${textEditStyle}
                            `}
                            variant="outlined"
                            onChange={(e) => this.onSubjectChange(e.target.value)}
                            value={subjectText}
                        />
                    </div>
                )}
            </ClassNames>
        )
    }

    private renderNotificationBody = (bodyError?: string) => {
        const { notification } = this.props
        const bodyText = notification && notification.body ? notification.body : ''

        return (
            <ClassNames>
                {({ css }) => (
                    <div>
                        <label
                            className={css`
                                ${labelStyle}
                            `}>
                            {translate('NOTIFICATION_BODY')}*
                        </label>
                        <NewDialogTextField
                            id="body_text"
                            required
                            multiline={true}
                            className={css`
                                .MuiOutlinedInput-inputMultiline {
                                    min-height: 106px;
                                    resize: vertical;
                                }
                                ${errorMsgStyle}
                            `}
                            helperText={
                                <LengthCounterHelperText
                                    errorText={bodyError}
                                    maxValue={10000}
                                    value={bodyText}
                                />
                            }
                            variant="outlined"
                            onChange={(e) => this.onBodyChange(e.target.value)}
                            value={bodyText}
                        />
                    </div>
                )}
            </ClassNames>
        )
    }

    private emailChips = (props: any) => {
        const { notification } = this.props

        const invalid =
            notification.invalidEmails && notification.invalidEmails.has(props.data.text)
        return (
            <UserChip
                className={invalid ? 'invalid' : ''}
                name={props.data.text}
                email={props.data.email}
                onDelete={props.removeProps.onClick}
            />
        )
    }

    private renderDialogContent = () => {
        const { toError, bodyError, subjError } = this.getValidationErrors()
        const existingAddressees = this.getExistingAddresseeData()
        return (
            <ClassNames>
                {({ css }) => (
                    <>
                        <InputLabelStyled
                            style={{ color: toError ? ColorBaseDarkRed : ColorBaseDarkBlack }}
                            required>
                            {translate('SEND_INVITATION_TO')}
                        </InputLabelStyled>
                        <ChipInputField
                            aria-required="true"
                            aria-label={i18n.t('NOTIFICATION_TO')}
                            className={css`
                                margin-bottom: 16px;
                                ${Assignees}${errorMsgStyle}${AssigneeTextField}
                            `}
                            helperText={toError}
                            options={this.renderAddresseeDataSource()}
                            aria-invalid={!!toError}
                            components={{ MultiValue: this.emailChips }}
                            value={existingAddressees}
                            variant="outlined"
                            onChange={(options, action) =>
                                makeOnChipChange({
                                    addChip: this.onAddresseeAdd,
                                    deleteChip: this.onDeleteChip
                                })({ options, action })
                            }
                        />

                        {this.renderNotificationSubject(subjError)}

                        {this.renderNotificationBody(bodyError)}
                    </>
                )}
            </ClassNames>
        )
    }

    private renderTitle = () => {
        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            ${dialogHeaderStyle}
                        `}>
                        <p
                            className={css`
                                font-family: source sans pro;
                                font-weight: 600;
                            `}>
                            {translate('SEND_NOTIFICATION_TITLE')}
                        </p>
                    </div>
                )}
            </ClassNames>
        )
    }

    private renderNotificationDialog = (classes) => {
        const { notification, isSendingNotification, savedAction } = this.props

        if (isSendingNotification || savedAction !== null) {
            return null
        }

        const hasErrors =
            notification && notification.validationErrors && notification.validationErrors.size > 0

        const sendButtonDisabled = !!hasErrors || isSendingNotification
        const cancelButton = (
            <AddActionEditActionButton
                key="cancel"
                onClick={this.onCloseDialog}
                disableFocusRipple
                disableRipple
                disabled={isSendingNotification}>
                {translate('CANCEL')}
            </AddActionEditActionButton>
        )

        const okButton = (
            <StyledAtlasButtonOld
                key="send"
                disabled={sendButtonDisabled}
                disableFocusRipple
                disableRipple
                onClick={this.onNotify}>
                {translate('NOTIFY_BUTTON_TEXT')}
            </StyledAtlasButtonOld>
        )

        return (
            <ClassNames>
                {({ css }) => (
                    <Dialog
                        className={css`
                            .MuiPaper-root {
                                max-height: 100%;
                                max-width: 1000px;
                                overflow-y: hidden;
                                border-bottom: 2px solid ${ColorBaseGreyNew};
                            }
                        `}
                        scroll="paper"
                        role="button"
                        fullWidth
                        maxWidth="md"
                        aria-labelledby={i18n.t('SEND_NOTIFICATION_ARIA_LABELLEDBY')}
                        open={true}
                        onClose={this.onCloseDialog}>
                        <NewDialogTitle
                            className={css`
                                margin-bottom: 0.9rem;
                            `}
                            id={i18n.t('SEND_NOTIFICATION_ARIA_LABELLEDBY')}>
                            {this.renderTitle()}
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                onClick={this.onCloseDialog}
                                className={classes.iconButton}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>

                        <DialogContent>{this.renderDialogContent()}</DialogContent>
                        <DialogActions>{[cancelButton, okButton]}</DialogActions>
                        <DomHelper onResize={this.onResize} />
                    </Dialog>
                )}
            </ClassNames>
        )
    }

    render() {
        const { classes } = this.props
        return <div>{this.renderNotificationDialog(classes)}</div>
    }
}

//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        notification: selectors.notification(state.minuteActionItemsReducer),
        dateFormat: selectors.dateFormat(state.minuteActionItemsReducer),
        savedAction: selectors.savedAction(state.minuteActionItemsReducer),
        isSendingNotification: selectors.isSendingNotification(state.minuteActionItemsReducer),
        platform: selectors.platform(state.minuteActionItemsReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const { initNotification, validateNotification, resetNotification, sendNotification } = actions
    const { editActionDashboardFullfilled } = actionDashboard
    return {
        actions: bindActionCreators(
            {
                initNotification,
                validateNotification,
                resetNotification,
                sendNotification,
                editActionDashboardFullfilled
            },
            dispatch
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationView))
//#endregion
