import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react'
import { Box, Paper, Typography, makeStyles } from '@material-ui/core'
import { actions } from 'reducers/applicationReducer'
import { bindActionCreators } from 'redux'
import { actions as takerActions } from '../../../reducers/minuteTakerReducer'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import takerSelectors from '../../../selectors/minuteTakerSelectors'
import actionEditorSelectors from '../../../selectors/actionEditorSelectors'
import { actions as editorActions } from '../../../reducers/actionEditorReducer'
import RightDrawer from './RightDrawer'
import { generateMeetingData } from './InvitationView/util'
import { getColumnSchema, OverlayNoRowsTemplate } from './ColumnComponents'
import { translate } from 'components/shared/internationalization'
import { ColDef } from 'ag-grid-community'
import { CustomSortIconsObject } from 'assets/icons/CustomSortIcon'
import { ActionData, CurrentMinutesProps, Details } from 'Types'
import LinearIndeterminate from 'components/shared/LinearProgress'
import i18n from 'common/util/internationalization'

const GridComponent = lazy(() => import('components/shared/table/AgGrid'))

type Props = {
    actions: {
        handleActionStatusChanged: (...args: any[]) => void
        selectActionItem: (...args: any[]) => void
        saveExistingAction: (...args: any[]) => any
        updateTakerListAction: (...args: any[]) => void
    }
    savedAction: any
    minutesActions: Array<any>
    minutesSections: Array<any>
    dateFormat: string
    selectedActionItem: Details
    currentMinuteItem: CurrentMinutesProps
    committeeId: any
    onDocumentStatusChange: (status: string) => void
    showSideBar: boolean
    saveComplete: any
    setIsLoading: any
} & RouteComponentProps

const useStyles = makeStyles(() => ({
    gridConatainer: {
        padding: '24px',
        gap: '8px',
        maxHeight: '68vh',
        overflow: 'auto'
    },
    headerText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#1E1E1E',
        width: '352px',
        height: '35px',
        gap: '0px',
        textWrap: 'nowrap'
    },
    customHeader: {
        backgroundColor: '#EBEEF2'
    }
}))

const MinutesActions: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const classes = useStyles()
    const [selectedAction, setSelectedAction] = useState<Details | null>(null)
    const committeeId = props.committeeId.id
    const handleEdit = useCallback(
        (params: Details) => {
            setIsOpen(true)
            params.committee_id = committeeId
            setSelectedAction(params)
        },
        [setSelectedAction, setIsOpen, committeeId]
    )
    const columns = useMemo(() => getColumnSchema(classes, handleEdit), [
        classes,
        handleEdit
    ]) as ColDef[]
    const { committeeName } = props.currentMinuteItem.attributes

    const handleNotificationStatusChange = useCallback(
        (data) => {
            const keys = Object.keys(data.actionItems)[0]
            const updatedData = data.actionItems[keys]
            if (selectedAction?.id && updatedData) {
                setSelectedAction((prev) => ({
                    ...(prev ?? ({} as any)),
                    notificationStatus: updatedData?.attributes?.notificationStatus
                }))
            }
        },
        [selectedAction]
    )

    const data: ActionData[] = useMemo(
        () => generateMeetingData(props.minutesActions, committeeName, props.minutesSections),
        [props, committeeName]
    )

    const RightDrawerMemo = useMemo(
        () => (
            <RightDrawer
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                selectedAction={selectedAction}
                handleNotificationStatusChange={handleNotificationStatusChange}
            />
        ),
        [isOpen, selectedAction, handleNotificationStatusChange]
    )
    const gridOptions = {
        headerClass: classes.customHeader,
        icons: {
            ...CustomSortIconsObject
        },
        headerHeight: 45,
        enableColResize: true
    }
    const handleCellValueChanged = useCallback(
        (params: { data: Details }) => {
            props.setIsLoading(true)
            props.actions.saveExistingAction(params.data).then(() => {
                props.setIsLoading(false)
            })
        },
        [props]
    )

    return (
        <Box>
            {RightDrawerMemo}
            <Suspense fallback={<LinearIndeterminate loading={true} />}>
                <Box className={classes.gridConatainer}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            aria-label={i18n.t('MINUTES_ACTION_HEADER')}
                            className={classes.headerText}>
                            {translate('MINUTES_ACTION_HEADER')}
                        </Typography>
                    </Box>
                    <Paper elevation={4}>
                        <GridComponent
                            gridId="minutes-actions"
                            columns={columns}
                            data={data}
                            gridOptions={gridOptions}
                            onCellValueChanged={handleCellValueChanged}
                            triggerColumnResizing={!props.showSideBar}
                            OverlayNoRowsTemplate={OverlayNoRowsTemplate}
                        />
                    </Paper>
                </Box>
            </Suspense>
        </Box>
    )
}

const mapStateToProps = (state: any, _: any) => ({
    minutesActions: takerSelectors.minutesActions(state.minuteTakerReducer),
    minutesSections: takerSelectors.minutesSections(state.minuteTakerReducer),
    dateFormat: takerSelectors.dateFormat(state.minuteTakerReducer),
    selectedActionItem: takerSelectors.selectedActionItem(state.minuteTakerReducer),
    currentMinuteItem: takerSelectors.currentMinuteItem(state.minuteTakerReducer),
    committeeId: takerSelectors.committeeId(state.minuteTakerReducer),
    savedAction: actionEditorSelectors.savedAction(state.actionEditorReducer)
})

const mapDispatchToProps = (dispatch: any) => {
    const { selectActionItem, saveExistingAction, updateTakerListAction } = {
        ...actions,
        ...takerActions,
        ...editorActions
    }

    return {
        actions: bindActionCreators(
            {
                selectActionItem,
                saveExistingAction,
                updateTakerListAction
            },
            dispatch
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinutesActions))
