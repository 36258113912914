import React, { useCallback, forwardRef, FC, useEffect } from 'react'
import {
    Box,
    Checkbox,
    Theme,
    ThemeProvider,
    Tooltip,
    Typography,
    createMuiTheme,
    makeStyles
} from '@material-ui/core'
import { ColorBaseMulberryBlue, ColorBaseWhite, FontWeightRegular } from 'assets/styles/variables'
import { ColDef, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community'
import i18n from 'common/util/internationalization'
import moment from 'moment'
import NoActionIcon from 'assets/icons/NoActionIcon'
import { formatDate } from 'common/formatters/date'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { useMinutesManager } from 'pages/minutes-manager-page/use-minutes-manager'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: FontWeightRegular,
        fontStyle: 'underline',
        cursor: 'pointer',
        textDecoration: 'none',
        minWidth: '100px'
    },
    checkbox: {
        padding: '0px',
        color: '#2F3B4D',
        '&.Mui-checked': {
            color: '#2F3B4D'
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        marginRight: '8px',
        width: '24px',
        height: '24px',
        '&:active': {
            boxShadow: `0px 0px 0px 3px #0774EE, 0px 0px 0px 1px ${ColorBaseWhite}`,
            borderRadius: '4px'
        },
        '&.Mui-focusVisible': {
            boxShadow: 'none',
            border: 'none'
        }
    },
    checkboxTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0px',
        color: '#000000',
        textTransform: 'capitalize'
    },
    checkBoxContainer: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '2px',
        placeItems: 'center',
        display: 'flex',
        minWidth: '100px',
        wordBreak: 'break-word',
        whiteSpace: 'normal',

        '&:focus': {
            outline: 'none',
            border: `2px solid ${ColorBaseMulberryBlue}`,
            boxShadow: `0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    skeletonLoading: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px'
    },
    skeletonLoadingItem: {
        height: '40px',
        backgroundColor: '#a5a5a5',
        borderRadius: '4px',
        animation: '$pulse 1.5s infinite'
    },
    '@keyframes pulse': {
        '0%': { opacity: 0.3 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 0.3 }
    },
    textField: {
        textTransform: 'capitalize',
        color: '#000000',
        display: 'inline-block',
        verticalAlign: 'middle',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '160px',
        minWidth: '100px'
    },
    customHeader: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    headerName: {
        marginRight: theme.spacing(1)
    },
    noDataContainer: {
        placeContent: 'center',
        placeItems: 'center',
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        height: '100%',
        minHeight: '400px'
    },
    noDataTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '32px',
        textAlign: 'center',
        color: '#000000'
    },
    noDatabodyText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'center',
        color: '#000000'
    },
    textField2: {
        verticalAlign: 'middle',
        maxWidth: '160px',
        minWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    wrappedText: {
        whiteSpace: 'normal',
        wordBreak: 'break-word'
    }
}))

interface TextComponentProps {
    colDef: ColDef
    valueFormatted?: string
    data: any
}

const TextComponent: React.FC<TextComponentProps> = React.memo((params) => {
    const classes = useStyles()
    const key = params.colDef.field as string
    const value = params.valueFormatted ?? params.data[key]

    return (
        <Tooltip title={value} disableHoverListener={value && value?.length <= 20}>
            <Typography variant="body2" className={classes.textField}>
                {value}
            </Typography>
        </Tooltip>
    )
})
const StatusCellRenderer = React.memo(({ value: status }: { value: string }) => {
    let color
    const classes = useStyles()
    switch (status) {
        case 'pending':
            color = 'gray'
            break
        case 'complete':
            color = '#05704B'
            break
        case 'incomplete':
            color = 'red'
            break
        case 'overdue':
            color = 'orange'
            break
        default:
            color = 'gray'
            break
    }

    return (
        <Box display="flex" alignItems="center" className={classes.checkBoxContainer}>
            <Box width={10} height={10} marginRight={1} bgcolor={color}></Box>
            <Typography variant="body2" style={{ textTransform: 'capitalize', color: '#000000' }}>
                {status}
            </Typography>
        </Box>
    )
})

const CheckBoxCellRenderer = forwardRef((params: any) => {
    const classes = useStyles()
    const { isAdminOfCommittee } = useMinutesManager()
    const isAdmin = isAdminOfCommittee(getSessionStorageItem('currentCommitteeId'))
    const isMemberSelfManage = !params.data.self_manage && !isAdmin
    const handleChange = useCallback(
        (event) => {
            if (!isMemberSelfManage) {
                const newValue = event.target.checked ? 'complete' : 'incomplete'
                params.setValue(newValue)
            }
        },
        [params, isMemberSelfManage]
    )
    const toggleCheckbox = useCallback(() => {
        if (!isMemberSelfManage) {
            const newValue =
                params.data[params.colDef.field] !== 'complete' ? 'complete' : 'incomplete'
            params.setValue(newValue)
        }
    }, [params, isMemberSelfManage])
    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                toggleCheckbox()
            }
        },
        [toggleCheckbox]
    )
    useEffect(() => {
        if (params.eGridCell) {
            params.eGridCell.addEventListener('keydown', handleKeyDown)
        }
        return () => {
            params.eGridCell.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown, params.eGridCell])
    const adminRestrictedChange = i18n.t('ADMIN_RESTRICTED_CHANGE')
    return (
        <Tooltip title={isMemberSelfManage ? adminRestrictedChange : ''} placement="top-start">
            <Box
                onClick={toggleCheckbox}
                style={{ cursor: isMemberSelfManage ? 'not-allowed' : 'pointer' }}
                className={classes.checkBoxContainer}
                tabIndex={0}
                role="checkbox">
                <Checkbox
                    disabled={isMemberSelfManage}
                    checked={params.data[params.colDef.field] === 'complete'}
                    tabIndex={-1}
                    role="checkbox"
                    className={classes.checkbox}
                    onChange={handleChange}
                    color="default"
                />
                <Typography
                    style={{ color: isMemberSelfManage ? 'rgba(0, 0, 0, 0.38)' : '#1e1e1e' }}
                    color="primary"
                    variant="body2"
                    className={classes.checkboxTitle}>
                    {i18n.t('ACTION_ITEM_COMPLETE')}
                </Typography>
            </Box>
        </Tooltip>
    )
})
const SkeletonLoading = React.memo(() => {
    const classes = useStyles()
    return (
        <div className={classes.skeletonLoading}>
            <div className={classes.skeletonLoadingItem} style={{ width: '60%' }}></div>
            <div className={classes.skeletonLoadingItem} style={{ width: '100%' }}></div>
            <div className={classes.skeletonLoadingItem} style={{ width: '80%' }}></div>
            <div className={classes.skeletonLoadingItem} style={{ width: '90%' }}></div>
            <div className={classes.skeletonLoadingItem} style={{ width: '80%' }}></div>
        </div>
    )
})
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontFamily: 'Source Sans Pro',
                maxWidth: '250px',
                maxHeight: '250px',
                boxShadow: 'none',
                overflowY: 'auto',
                whiteSpace: 'pre-wrap'
            }
        }
    }
})
interface TruncatedCellRendererProps {
    value: string
    maxLength: number
}

const TruncatedCellRenderer: FC<TruncatedCellRendererProps> = ({ value, maxLength }) => {
    const classes = useStyles()
    const isTruncated = value.length > maxLength
    const displayValue = isTruncated ? `${value.substring(0, maxLength)}...` : value

    return isTruncated ? (
        <ThemeProvider theme={theme}>
            <Tooltip interactive title={value}>
                <Typography
                    variant="body2"
                    className={`${classes.textField2} ${!isTruncated && classes.wrappedText}`}>
                    {displayValue}
                </Typography>
            </Tooltip>
        </ThemeProvider>
    ) : (
        <Typography
            variant="body2"
            className={`${classes.textField2} ${!isTruncated && classes.wrappedText}`}>
            {displayValue}
        </Typography>
    )
}
interface NavigateCellRendererProps {
    data: any
    handleEdit: (data: any) => void
    baseKey: string
}
const NavigateCellRenderer: React.FC<NavigateCellRendererProps> = React.memo(
    ({ data, handleEdit, baseKey }) => {
        const classes = useStyles()
        const onClickHandler = useCallback(() => handleEdit(data), [data, handleEdit])
        const getTitle = () => {
            const title = baseKey ? data?.[baseKey] : data?.action_title
            const truncationLimit = 150
            const isTitleTruncated = title?.length > truncationLimit
            const truncatedTitle = isTitleTruncated
                ? `${title.substring(0, truncationLimit)}...`
                : title

            return { title, truncatedTitle, isTitleTruncated }
        }

        const { title, truncatedTitle, isTitleTruncated } = getTitle()

        return (
            <ThemeProvider theme={theme}>
                <Tooltip title={isTitleTruncated ? title : ''} arrow interactive placement="top">
                    <Box
                        id="ContentContainer_ContentBody"
                        data-analytics="MM-ActionItemLink"
                        onClick={onClickHandler}
                        className={classes.checkBoxContainer}>
                        <Typography className={classes.link}>{truncatedTitle}</Typography>
                    </Box>
                </Tooltip>
            </ThemeProvider>
        )
    }
)
interface ColumnSchema {
    headerName: string
    field?: string
    sortable?: boolean
    width?: number
    cellRendererFramework?: any
    valueFormatter?: (
        params: ValueFormatterParams<any, any>
    ) => string | ValueFormatterFunc<any> | any
    editable?: boolean
    headerClass?: string
}
const createColumn = (
    classes,
    headerName: string,
    field?: string,
    valueFormatter?: (
        params: ValueFormatterParams<any, any>
    ) => string | ValueFormatterFunc<any> | any,
    _editable: boolean = false,
    cellRendererFramework?: any
): ColumnSchema => ({
    headerName: i18n.t(headerName),
    field,
    sortable: true,
    cellRendererFramework,
    valueFormatter,
    headerClass: classes.customHeader
})

// const pinnedObjects = isTabletOrMobile ? { pinned: 'right' } : {} as any
const getColumnSchema = (classes: any, handleEdit: (data: any) => void): ColDef[] => [
    {
        ...createColumn(
            classes,
            'ACTION_TABLE_HEADER',
            'action_title',
            undefined,
            false,

            (params) => {
                return (
                    <NavigateCellRenderer
                        handleEdit={handleEdit}
                        data={params.data}
                        baseKey="action_title"
                    />
                )
            }
        ),

        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            whiteSpace: 'normal',
            font: '700 16px/20px Source Sans Pro',
            color: '#385F99',
            cursor: 'pointer',
            wordBreak: 'break-word',
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(
            classes,
            'MINUTES_SECTION',
            'minutes_meeting',
            undefined,
            false,
            (params) => <TruncatedCellRenderer value={params.data.minutes_meeting} maxLength={50} />
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(classes, 'DUE_DATE_ALT', 'due_date', ({ data }: any) => {
            const { dateFormat } = transientStorageManager
            if (!data?.due_date || !moment(data?.due_date).isValid()) {
                return i18n.t('NO_DUE_DATE')
            }
            return formatDate({
                date: data?.due_date,
                dateFormat
            })
        }),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    },

    {
        ...createColumn(classes, 'ASSIGNED_TO', 'assigned_to', undefined, false, (params) => (
            <TruncatedCellRenderer value={params.data.assigned_to} maxLength={50} />
        )),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_COMPLETED',
            'completionStatus',
            undefined,
            true,
            CheckBoxCellRenderer
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_ITEM_STATUS',
            'status',
            undefined,
            false,
            StatusCellRenderer
        ),
        width: 221,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(classes, 'NOTIFICATION', 'notificationStatus', ({ data }: any) => {
            if (data?.notificationStatus === 'sent') {
                return i18n.t('YES')
            } else {
                return i18n.t('NO')
            }
        }),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px',
            alignItems: 'center'
        }
    }
]

const getActionColumnSchemaForAdmin = (
    classes: any,
    handleEdit: (data: any) => void
): ColDef[] | any => [
    {
        ...createColumn(
            classes,
            'ACTION_TABLE_HEADER',
            'text',
            undefined,
            false,

            (params) => {
                return (
                    <NavigateCellRenderer
                        handleEdit={handleEdit}
                        data={params.data}
                        baseKey="text"
                    />
                )
            }
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            whiteSpace: 'normal',
            font: '700 16px/20px Source Sans Pro',
            color: '#385F99',
            cursor: 'pointer',
            wordBreak: 'break-word',
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'COMMITTEE', 'committee'),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'FILTER_OPTIONS.MINUTES_DOCUMENT',
            'document_title',
            undefined,
            false,
            (params) => (
                <TruncatedCellRenderer
                    value={params.data.document_title ? params.data.document_title : ''}
                    maxLength={50}
                />
            )
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'MINUTES_SECTION', 'section_name', undefined, false, (params) => (
            <TruncatedCellRenderer
                value={params.data.section_name ? params.data.section_name : ''}
                maxLength={50}
            />
        )),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'DUE_DATE_ALT', 'due_date', ({ data }: any) => {
            const { dateFormat } = transientStorageManager
            if (!data?.due_date || !moment(data?.due_date).isValid()) {
                return i18n.t('NO_DUE_DATE')
            }
            return formatDate({
                date: data?.due_date,
                dateFormat
            })
        }),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },

    {
        ...createColumn(classes, 'ASSIGNED_TO', 'assigned_to', undefined, false, (params) => (
            <TruncatedCellRenderer
                value={params.data.assigned_to ? params.data.assigned_to : ''}
                maxLength={50}
            />
        )),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_COMPLETED',
            'action_completed',
            undefined,
            true,
            CheckBoxCellRenderer
        ),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_ITEM_STATUS',
            'status',
            undefined,
            false,
            StatusCellRenderer
        ),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'COMPLETED_DATE', 'completed_date', ({ data }: any) => {
            const { dateFormat } = transientStorageManager
            if (!data?.completed_date || !moment(data?.completed_date).isValid()) {
                return i18n.t('NO_DUE_DATE')
            }
            return formatDate({
                date: data?.completed_date,
                dateFormat: dateFormat
            })
        }),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'NOTIFICATION', 'notification_status', ({ data }: any) => {
            if (data?.notificationStatus === 'sent') {
                return i18n.t('YES')
            } else {
                return i18n.t('NO')
            }
        }),
        width: 250,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'NOTES',
            'notes',
            undefined,
            false,

            (params) => {
                return (
                    <TruncatedCellRenderer
                        maxLength={50}
                        value={params.data.notes ? params.data.notes : ''}
                    />
                )
            }
        ),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    }
]

const getActionColumnSchemaForDirector = (
    classes: any,
    handleEdit: (data: any) => void
): ColDef[] | any => [
    {
        ...createColumn(
            classes,
            'ACTION_TABLE_HEADER',
            'text',
            undefined,
            false,

            (params) => {
                return (
                    <NavigateCellRenderer
                        handleEdit={handleEdit}
                        data={params.data}
                        baseKey="text"
                    />
                )
            }
        ),
        width: 221,
        minWidth: 350,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            whiteSpace: 'normal',
            font: '700 16px/20px Source Sans Pro',
            color: '#385F99',
            cursor: 'pointer',
            wordBreak: 'break-word',
            padding: '14px 16px',
            alignItems: 'center'
        }
    },
    {
        ...createColumn(classes, 'ASSIGNED_TO', 'assigned_to', undefined, false, (params) => (
            <TruncatedCellRenderer
                value={params.data.assigned_to ? params.data.assigned_to : ''}
                maxLength={50}
            />
        )),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true
    },
    {
        ...createColumn(classes, 'DUE_DATE_ALT', 'due_date', ({ data }: any) => {
            const { dateFormat } = transientStorageManager
            if (!data?.due_date || !moment(data?.due_date).isValid()) {
                return i18n.t('NO_DUE_DATE')
            }
            return formatDate({
                date: data?.due_date,
                dateFormat
            })
        }),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_ITEM_STATUS',
            'status',
            undefined,
            false,
            StatusCellRenderer
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'ACTION_COMPLETED',
            'action_completed',
            undefined,
            true,
            CheckBoxCellRenderer
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'COMPLETED_DATE', 'completed_date', ({ data }: any) => {
            const { dateFormat } = transientStorageManager
            if (!data?.completed_date || !moment(data?.completed_date).isValid()) {
                return i18n.t('NO_DUE_DATE')
            }
            return formatDate({
                date: data?.completed_date,
                dateFormat: dateFormat
            })
        }),
        width: 221,
        minWidth: 160,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'NOTES',
            'notes',
            undefined,
            false,

            (params) => {
                return (
                    <TruncatedCellRenderer
                        maxLength={50}
                        value={params.data.notes ? params.data.notes : ''}
                    />
                )
            }
        ),
        width: 221,
        minWidth: 150,
        hide: false,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(
            classes,
            'FILTER_OPTIONS.MINUTES_DOCUMENT',
            'document_title',
            undefined,
            false,
            (params) => (
                <TruncatedCellRenderer
                    value={params.data.document_title ? params.data.document_title : ''}
                    maxLength={50}
                />
            )
        ),
        width: 221,
        minWidth: 150,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'MINUTES_SECTION', 'section_name', undefined, false, (params) => (
            <TruncatedCellRenderer
                value={params.data.section_name ? params.data.section_name : ''}
                maxLength={50}
            />
        )),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    },
    {
        ...createColumn(classes, 'COMMITTEE', 'committee'),
        width: 221,
        minWidth: 150,
        hide: true,
        comparator: () => 0,
        suppressSorting: true,
        cellStyle: {
            padding: '14px 16px'
        }
    }
]

const OverlayNoRowsTemplate: FC = ({ formData }: any) => {
    const classes = useStyles()
    const isFiltered = formData ? Object.keys(formData).length > 0 : false
    return (
        <Box className={classes.noDataContainer}>
            <NoActionIcon />
            <Typography className={classes.noDataTitle}>
                {i18n.t('NO_ACTIONS_TO_DISPLAY')}
            </Typography>
            <Typography className={classes.noDatabodyText}>
                {isFiltered
                    ? i18n.t('NO_ACTIONS_TO_DISPLAY_SUGGESTION_ON_FILTERS')
                    : i18n.t('NO_ACTIONS_TO_DISPLAY_SUGGESTION')}
            </Typography>
        </Box>
    )
}
export {
    StatusCellRenderer,
    CheckBoxCellRenderer,
    SkeletonLoading,
    NavigateCellRenderer,
    TextComponent,
    getColumnSchema,
    OverlayNoRowsTemplate,
    getActionColumnSchemaForAdmin,
    getActionColumnSchemaForDirector
}
